import React, { Component } from "react";
import './styles.css'
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Fade, Grid, IconButton, LinearProgress, MenuItem, Modal, TextField, Typography } from "@mui/material";
import moment from "moment";
import StartFirebase from '../../configFirebase/index';
import { ref, set } from "firebase/database";
import { CheckCircle, Close, ExpandMore, HelpOutlineOutlined } from "@mui/icons-material";
import Hematologi from "../Hematologi";
import FungsiHati from "../Hati";
import FungsiGinjal from "../Ginjal";

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

class RegistrasiPasien extends Component {
  state = {
    modalSubmit: false,
    loading: false,
    database: [],
    showFields: false,
    dataPasien: {},
    loginAs: localStorage.getItem('penunjang'),
    expanded: false,
    dataLab: null,
    confirm: false,
    pemeriksa: '',

    // Radiologi
    dataPasienRadiologi: {},
    showFieldsRadiologi: false,
    keteranganRadiologi: '',
    pemeriksaRadiologi: '',
  }

  componentDidMount() {
    this.setState({ database: StartFirebase() });
  }

  _handleChangeNoRM = (e, val) => {
    this.setState({ dataPasien: val, showFields: !this.state.showFields });
  }

  _handleChangeNoRMRadiologi = (e, val) => {
    this.setState({ dataPasienRadiologi: val, showFieldsRadiologi: !this.state.showFieldsRadiologi });
  }

  _handleChangeKeterangan = (e) => {
    this.setState({ keteranganRadiologi: e.target.value })
  }

  _handleChangeValRM = (e) => {
    this.setState({ dataPasien: e.target.value });
  }

  _handleChangeValRMRadiologi = (e) => {
    this.setState({ dataPasienRadiologi: e.target.value });
  }

  _handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  _handleGoToList = () => {
    window.location.reload();
  }

  _handleCloseModal = (e) => {
    e.preventDefault();
    this.setState({ modalSubmit: false });
  }

  _handleExpansion = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false })
  }

  _handleValueHematology = (value) => {
    this.setState({
      dataLab: { ...this.state.dataLab, ...value }
    })
  }

  _handleValueLiver = (value) => {
    this.setState({
      dataLab: { ...this.state.dataLab, ...value }
    })
  }

  _handleValueKidney = (value) => {
    this.setState({
      dataLab: { ...this.state.dataLab, ...value }
    })
  }

  _handleChangePemeriksa = (e) => {
    this.setState({ pemeriksa: e.target.value })
  }

  _handleChangePemeriksaRadiologi = (e) => {
    this.setState({ pemeriksaRadiologi: e.target.value })
  }

  _handleConfirm = () => {
    this.setState({ confirm: !this.state.confirm });
  }

  _handleSaveResume = (e) => {
    const {
      dataPasien, database, dataLab, pemeriksa, loginAs,
      dataPasienRadiologi, pemeriksaRadiologi, keteranganRadiologi
    } = this.state;
    e.preventDefault();
    let db = database;

    if (loginAs === 'Radiologi') {
      let data = {
        ...dataPasienRadiologi, isInspectionRadiologi: true,
        hasilRadiologi: {
          pemeriksa: pemeriksaRadiologi,
          keterangan: keteranganRadiologi
        }
      };
      set(ref(db, 'pasien/' + data.queueNumber), { ...data });
      this.setState({ modalSubmit: true, loading: true },
        () => setTimeout(() => this.setState({ loading: false }), 500));
    } else {
      let data = {
        ...dataPasien, isInspectionLab: true,
        hasilLab: { ...dataLab, pemeriksa: pemeriksa }
      };
      set(ref(db, 'pasien/' + data.queueNumber), { ...data });
      this.setState({ modalSubmit: true, loading: true },
        () => setTimeout(() => this.setState({ loading: false }), 500));
    }
  }

  _renderModalSubmit = () => {
    return (
      <Modal open={this.state.modalSubmit}>
        <Box sx={styleModal}>
          {this.state.loading ?
            <Box sx={{ width: '100%' }}>
              <h3>Menyimpan Data...</h3>
              <LinearProgress />
            </Box> :
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <div style={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}>
                <IconButton onClick={this._handleCloseModal}>
                  <Close />
                </IconButton>
              </div>
              <CheckCircle color='success' sx={{ fontSize: 150 }} />
              <h3>Penyimpanan data berhasil!</h3>
              <Button
                variant="contained"
                color="success"
                onClick={this._handleGoToList}
              >
                Lihat List Pasien
              </Button>
            </div>
          }
        </Box>
      </Modal>
    );
  }

  _renderModalConfirm = () => {
    return (
      <Modal open={this.state.confirm}>
        <Box sx={styleModal}>
          <div style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}>
            <IconButton onClick={this._handleConfirm}>
              <Close />
            </IconButton>
          </div>
          <HelpOutlineOutlined sx={{ fontSize: '150px', color: '#18c44b' }} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>Apakah data yang anda inputkan sudah yakin?</div>
            <div style={{ marginTop: '10px' }}>
              <Button variant="outlined" sx={{ marginRight: '10px' }} onClick={this._handleConfirm}>KEMBALI</Button>
              <Button variant="contained" onClick={this._handleSaveResume}>YA</Button>
            </div>
          </div>
        </Box>
      </Modal>
    )
  }

  _renderFormLab = (newData) => {
    const { showFields, dataPasien, expanded } = this.state;
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Autocomplete
              className='wrappField'
              disablePortal
              id="combo-box-demo"
              onChange={this._handleChangeNoRM}
              options={newData}
              getOptionLabel={(option) => option && option.noRM}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label="No Rekam Medis"
                  onChange={this._handleChangeValRM}
                />
              }
            />
          </Grid>
          <Grid item xs={3}>
            {showFields &&
              <>
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="Nama Lengkap"
                  value={dataPasien?.namaLengkap}
                />
              </>
            }
          </Grid>
          <Grid item xs={3}>
            {showFields &&
              <>
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="Tanggal Lahir"
                  value={moment(dataPasien?.tglLahir).format('DD-MM-YYYY')}
                />
              </>
            }
          </Grid>
          <Grid item xs={3}>
            {showFields &&
              <>
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="Jenis Kelamin"
                  value={dataPasien?.jenisKelamin}
                />
              </>
            }
          </Grid>
        </Grid>
        {showFields &&
          <>
            <Accordion
              expanded={expanded === 'panel1'}
              onChange={this._handleExpansion('panel1')}
              slots={{ transition: Fade }}
              slotProps={{ transition: { timeout: 400 } }}
              sx={{
                '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ backgroundColor: '#20387d', marginTop: '10px', marginBottom: '10px' }}
              >
                <Typography sx={{ color: '#FFF' }}><b>Hematology Lengkap</b></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Hematologi
                  kelamin={dataPasien?.jenisKelamin}
                  onValueHematology={this._handleValueHematology}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={this._handleExpansion('panel2')}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{ backgroundColor: '#20387d', marginTop: '10px', marginBottom: '10px' }}
              >
                <Typography sx={{ color: '#FFF' }}><b>Fungsi Hati</b></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FungsiHati onValueLiver={this._handleValueLiver} />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={this._handleExpansion('panel3')}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{ backgroundColor: '#20387d', marginTop: '10px', marginBottom: '10px' }}
              >
                <Typography sx={{ color: '#FFF' }}><b>Fungsi Ginjal</b></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FungsiGinjal
                  kelamin={dataPasien?.jenisKelamin}
                  onValueKidney={this._handleValueKidney}
                />
              </AccordionDetails>
            </Accordion>
            <TextField
              className='wrappFieldPemeriksa'
              error={this.state.pemeriksa === ''}
              id="outlined-select-currency"
              select
              label="Pemeriksa"
              fullWidth
              name='pemeriksa'
              onChange={this._handleChangePemeriksa}
            >
              {['Benzema, Amd.AK', 'Messi, Amd.AK', 'Ronaldo, Amd.AK', 'Neymar, Amd.AK', 'Suarez, Amd.AK'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <Button
              disabled={this.state.pemeriksa === ''}
              fullWidth
              variant='contained'
              onClick={this._handleConfirm}
              sx={{ marginTop: 2 }}
            >
              SIMPAN DATA
            </Button>
          </>
        }
      </>
    )
  }

  _renderFormRadiologi = (newData) => {
    const {
      showFieldsRadiologi, dataPasienRadiologi,
      pemeriksaRadiologi, keteranganRadiologi
    } = this.state;
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Autocomplete
              className='wrappField'
              disablePortal
              id="combo-box-demo"
              onChange={this._handleChangeNoRMRadiologi}
              options={newData}
              getOptionLabel={(option) => option && option.noRM}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label="No Rekam Medis"
                  onChange={this._handleChangeValRMRadiologi}
                />
              }
            />
          </Grid>
          <Grid item xs={3}>
            {showFieldsRadiologi &&
              <>
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="Nama Lengkap"
                  value={dataPasienRadiologi?.namaLengkap}
                />
              </>
            }
          </Grid>
          <Grid item xs={3}>
            {showFieldsRadiologi &&
              <>
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="Tanggal Lahir"
                  value={moment(dataPasienRadiologi?.tglLahir).format('DD-MM-YYYY')}
                />
              </>
            }
          </Grid>
          <Grid item xs={3}>
            {showFieldsRadiologi &&
              <>
                <TextField
                  disabled
                  fullWidth
                  className='wrappField'
                  label="Jenis Kelamin"
                  value={dataPasienRadiologi?.jenisKelamin}
                />
              </>
            }
          </Grid>
        </Grid>
        {showFieldsRadiologi &&
          <>
            <TextField
              multiline
              rows={15}
              type='area'
              label="Keterangan"
              name="keterangan"
              fullWidth
              onChange={this._handleChangeKeterangan}
            />
            <TextField
              className='wrappFieldPemeriksa'
              error={pemeriksaRadiologi === ''}
              id="outlined-select-currency"
              select
              label="Pemeriksa"
              fullWidth
              name='pemeriksa'
              onChange={this._handleChangePemeriksaRadiologi}
            >
              {['John Cena, Sp.Rad', 'Randy Orton, Sp.Rad', 'Hulk Hogan, Sp.Rad'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <Button
              disabled={pemeriksaRadiologi === '' || keteranganRadiologi === ''}
              fullWidth
              variant='contained'
              onClick={this._handleConfirm}
              sx={{ marginTop: 2 }}
            >
              SIMPAN DATA
            </Button>
          </>
        }
      </>
    )
  }

  render() {
    const { loginAs } = this.state;
    const { data } = this.props;
    let newData;
    if (loginAs === 'Radiologi') {
      newData = (data || []).filter(val => val?.isInspectionRadiologi === undefined && val?.dataResume?.needRadiologi);
    } else {
      newData = (data || []).filter(val => val?.isInspectionLab === undefined && val?.dataResume?.needLab);
    }

    return (
      <div className="containerContent">
        {this._renderModalSubmit()}
        {this._renderModalConfirm()}
        {loginAs === 'Radiologi' ? this._renderFormRadiologi(newData) : this._renderFormLab(newData)}
      </div>
    )
  }
}

export default RegistrasiPasien;