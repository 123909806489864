import styled from "@emotion/styled";
import { InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React, { Component } from "react";
import './styles.css';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': { backgroundColor: '#ebebeb' },
  '&:last-child td, &:last-child th': { border: 0 },
}));

class Hematologi extends Component {
  state = {
    hematalogy: null
  }

  _handleChange = (e) => {
    const { onValueHematology } = this.props;
    this.setState({
      hematalogy: {
        ...this.state.hematalogy,
        [e.target.name]: e.target.value
      }
    });
    setTimeout(() => { onValueHematology(this.state.hematalogy) }, 500);
  }

  render() {
    const { hematalogy } = this.state;
    const { kelamin } = this.props;
    return (
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell><b>PEMERIKSAAN</b></TableCell>
                <TableCell><b>HASIL</b></TableCell>
                <TableCell><b>NILAI RUJUKAN</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <TableCell component="th" scope="row">Hemoglobin (HGB)</TableCell>
                <TableCell>
                  <TextField
                    className="wrappFields"
                    name="hemoglobin"
                    onChange={this._handleChange}
                    value={hematalogy?.hemoglobin}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">g/dL</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell>{kelamin === 'Laki-Laki' ? '13 - 17' : '12 - 15'} g/dL</TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell component="th" scope="row">Erithrosit (RBC)</TableCell>
                <TableCell>
                  <TextField
                    className="wrappFields"
                    name="erithrosit"
                    onChange={this._handleChange}
                    value={hematalogy?.erithrosit}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">/&#181;L</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell>{kelamin === 'Laki-Laki' ? '4,7 - 6,1' : '4,2 - 5,4'} /&#181;L</TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell component="th" scope="row">Lekosit (WBC)</TableCell>
                <TableCell>
                  <TextField
                    className="wrappFields"
                    name="lekosit"
                    onChange={this._handleChange}
                    value={hematalogy?.lekosit}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">/&#181;L</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell>4.500 - 11.000 /&#181;L</TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell component="th" scope="row">Trombosit (PLT)</TableCell>
                <TableCell>
                  <TextField
                    className="wrappFields"
                    name="trombosit"
                    onChange={this._handleChange}
                    value={hematalogy?.trombosit}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">/&#181;L</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell>135.000 - 317.000 /&#181;L</TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell component="th" scope="row">LED</TableCell>
                <TableCell>
                  <TextField
                    className="wrappFields"
                    name="led"
                    onChange={this._handleChange}
                    value={hematalogy?.led}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">mm/jam</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell>0 - 20 mm/jam</TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }
}

export default Hematologi;