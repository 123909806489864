import { Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import React, { Component } from 'react';
import { parser } from './dataMaster';

const styles = StyleSheet.create({
  table: { width: '100%', marginTop: '20px' },
  table2: { width: '100%', marginTop: '2px' },
  row: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #000',
    paddingTop: 8,
    paddingBottom: 8,
  },
  header: { border: '1px solid #000', backgroundColor: 'tomato' },
  headerFarmasi: { border: '1px solid #000', backgroundColor: '#e8e9eb' },
  headerTotal: { border: '1px solid #000', backgroundColor: 'grey' },
  headerRadiologi: { border: '1px solid #000', backgroundColor: 'cornflowerblue' },
  headerTitle: { border: '1px solid #000', backgroundColor: 'grey' },
  bold: { fontWeight: 'bold' },
  row1: { width: '20%', borderRight: '1px solid #000' },
  row2: { width: '20%', borderRight: '1px solid #000' },
  row3: { width: '20%', borderRight: '1px solid #000' },
  row4: { width: '20%', borderRight: '1px solid #000' },
  row5: { width: '20%' },
  fontBold: { fontWeight: 'bold' },
  tandaVital: {
    row1: { width: '25%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row2: { width: '25%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row3: { width: '25%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row4: { width: '25%', textAlign: 'left', marginLeft: '5px' },
  },
  penunjang: {
    row1: { width: '33.2%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row2: { width: '33.2%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row3: { width: '33.2%', textAlign: 'left', marginLeft: '5px' },
  },
  title: {
    row: { width: '100%', textAlign: 'left', marginLeft: '5px' }
  },
  total: {
    row1: { width: '75%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row2: { width: '25%', textAlign: 'left', marginLeft: '5px' },
  },
  tindakan: {
    row1: { width: '50%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row2: { width: '50%', textAlign: 'left', marginLeft: '5px' },
  }
})

class PrintForm extends Component {
  state = {
    loginAs: localStorage.getItem('billing'),
  }

  render() {
    const { loginAs } = this.state;
    const { data } = this.props;
    const dataObat = (data && data[0] && data[0]?.billingFarmasi?.obatObatan) || [];
    const dataTindakan = (data && data[0] && data[0]?.billingKassa?.jenisTinakan) || [];
    let sumPriceObat = 0;
    let sumPriceTindakan = 0;
    dataObat?.forEach(val => { sumPriceObat += val?.total });
    dataTindakan?.forEach(val => { sumPriceTindakan += val?.charges });
    if (loginAs === 'Kassa') {
      return (
        <div>
          <div style={{ borderBottom: '1px solid black', textAlign: 'right', padding: 5 }}>
            <Text style={{ fontSize: 18 }}>Hermina Arcamanik Hospital (RSU Hermina Arcamanik)</Text>
            <Text>Jl. A.H. Nasution No.50, Antapani Wetan, Kec. Antapani</Text>
            <Text>Kota Bandung, Jawa Barat 40291</Text>
            <Text>Phone: 022-87242525. Fax: 022-7271771</Text>
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Text>BILLING KASSA</Text>

            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.headerRadiologi]}>
                <Text style={styles.row1}>NO. RM</Text>
                <Text style={styles.row2}>NAMA</Text>
                <Text style={styles.row3}>TGL LAHIR</Text>
                <Text style={styles.row4}>POLIKLINIK</Text>
                <Text style={styles.row5}>DPJP</Text>
              </View>
              {data.map((row, i) => (
                <View key={i} style={styles.row} wrap={false}>
                  <Text style={styles.row1}>{row.noRM}</Text>
                  <Text style={styles.row2}>{row.namaLengkap}</Text>
                  <Text style={styles.row3}>{moment(row?.tglLahir).format('DD/MM/YYYY')}</Text>
                  <Text style={styles.row4}>{row.poli}</Text>
                  <Text style={styles.row5}>{row.dokterPoli}</Text>
                </View>
              ))}
            </View>
            {dataObat?.length !== 0 &&
              <div>
                <div style={{ textAlign: 'left', marginTop: '20px' }}>
                  <Text>FARMASI OBAT</Text>
                </div>
                <View style={styles.table}>
                  <View style={[styles.row, styles.bold, styles.header]}>
                    <Text style={styles.tandaVital.row1}>NAMA OBAT</Text>
                    <Text style={styles.tandaVital.row2}>JUMLAH</Text>
                    <Text style={styles.tandaVital.row3}>HARGA SATUAN (Rp.)</Text>
                    <Text style={styles.tandaVital.row4}>TOTAL (Rp.)</Text>
                  </View>
                  {dataObat.map((row, i) => (
                    <View key={i} style={styles.row} wrap={false}>
                      <Text style={styles.tandaVital.row1}>{row.title}</Text>
                      <Text style={styles.tandaVital.row2}>{row.quantity}</Text>
                      <Text style={styles.tandaVital.row3}>{parser(row.charges)}</Text>
                      <Text style={styles.tandaVital.row4}>{parser(row.quantity * row.charges)}</Text>
                    </View>
                  ))}
                  <View style={[styles.row, styles.headerFarmasi]} wrap={false}>
                    <Text style={styles.total.row1}>TOTAL FARMASI OBAT (Rp.)</Text>
                    <Text style={styles.total.row2}>{parser(sumPriceObat)}</Text>
                  </View>
                </View>
              </div>
            }

            <div style={{ textAlign: 'left', marginTop: '20px' }}>
              <Text>JENIS TINDAKAN</Text>
            </div>
            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.header]}>
                <Text style={styles.total.row1}>KETERANGAN</Text>
                <Text style={styles.total.row2}>HARGA</Text>
              </View>
              {dataTindakan.map((row, i) => (
                <View key={i} style={styles.row} wrap={false}>
                  <Text style={styles.total.row1}>{row.title}</Text>
                  <Text style={styles.total.row2}>{parser(row.charges)}</Text>
                </View>
              ))}
              <View style={[styles.row, styles.headerFarmasi]} wrap={false}>
                <Text style={styles.total.row1}>TOTAL TINDAKAN FARMASI (Rp.)</Text>
                <Text style={styles.total.row2}>{parser(sumPriceTindakan)}</Text>
              </View>
            </View>

            <View style={[styles.table, styles.row, styles.headerTotal]} wrap={false}>
              <Text style={styles.total.row1}>TOTAL TRANSAKSI (Rp.)</Text>
              <Text style={styles.total.row2}>{parser(sumPriceTindakan + sumPriceObat)}</Text>
            </View>

            <Text style={{ textAlign: "right", marginTop: '30px', marginBottom: '30px' }}>PETUGAS KASSA</Text>
            <Text style={{ textAlign: "right" }}>{(data && data[0] && data[0]?.billingKassa?.petugas) || ''}</Text>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div style={{ borderBottom: '1px solid black', textAlign: 'right', padding: 5 }}>
            <Text style={{ fontSize: 18 }}>Hermina Arcamanik Hospital (RSU Hermina Arcamanik)</Text>
            <Text>Jl. A.H. Nasution No.50, Antapani Wetan, Kec. Antapani</Text>
            <Text>Kota Bandung, Jawa Barat 40291</Text>
            <Text>Phone: 022-87242525. Fax: 022-7271771</Text>
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Text>BILLING FARMASI</Text>

            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.headerRadiologi]}>
                <Text style={styles.row1}>NO. RM</Text>
                <Text style={styles.row2}>NAMA</Text>
                <Text style={styles.row3}>TGL LAHIR</Text>
                <Text style={styles.row4}>POLIKLINIK</Text>
                <Text style={styles.row5}>DPJP</Text>
              </View>
              {data.map((row, i) => (
                <View key={i} style={styles.row} wrap={false}>
                  <Text style={styles.row1}>{row.noRM}</Text>
                  <Text style={styles.row2}>{row.namaLengkap}</Text>
                  <Text style={styles.row3}>{moment(row?.tglLahir).format('DD/MM/YYYY')}</Text>
                  <Text style={styles.row4}>{row.poli}</Text>
                  <Text style={styles.row5}>{row.dokterPoli}</Text>
                </View>
              ))}
            </View>

            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.headerFarmasi]}>
                <Text style={styles.tindakan.row1}>NAMA OBAT</Text>
                <Text style={styles.tindakan.row2}>JUMLAH</Text>
              </View>
              {dataObat.map((row, i) => (
                <View key={i} style={styles.row} wrap={false}>
                  <Text style={styles.tindakan.row1}>{row.title}</Text>
                  <Text style={styles.tindakan.row2}>{row.quantity}</Text>
                </View>
              ))}
            </View>

            <Text style={{ textAlign: "right", marginTop: '30px', marginBottom: '30px' }}>PETUGAS FARMASI</Text>
            <Text style={{ textAlign: "right" }}>{(data && data[0] && data[0]?.billingFarmasi?.petugas) || ''}</Text>
          </div>
        </div>
      );
    }
  }
}

export default PrintForm;