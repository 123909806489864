/* eslint-disable no-unused-vars */
import { Add, CheckCircle, Close, ErrorOutline } from "@mui/icons-material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Autocomplete, Box, Button, FormControl, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Modal, Paper, Select, Table, TableBody, TableContainer, TableRow, TextField } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { dataObat, parser } from "./dataMaster";
import { styled } from '@mui/material/styles';
import { ref, set } from "firebase/database";
import StartFirebase from "../../configFirebase";

const styleModalForm = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  height: '70%',
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

const db = StartFirebase();

export default function RenderNewBillFarmasi({
  onConfirm, dataBill
}) {
  const [namaPetugas, setNamaPetugas] = useState('');
  const [addObat, setAddObat] = useState(false);
  const [showQuantity, setShowQuantity] = useState(false);
  const [listObat, setListObat] = useState([]);
  const [valObat, setValObat] = useState(null);
  const [confirmSaveBill, setConfirmSaveBill] = useState(false);
  const [modalSubmit, setModalSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const styleAddObat = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    height: '35%',
    bgcolor: 'background.paper',
    border: '1px solid grey',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
  };

  const styleConfirm = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: '25%',
    bgcolor: 'background.paper',
    border: '1px solid grey',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
  };

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid grey',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const _handleConfirmCloseObat = () => {
    onConfirm(true);
  }

  const handleAddObat = () => {
    setAddObat(true);
    setShowQuantity(false)
  }

  const _handleChangePetugas = (e) => {
    setNamaPetugas(e.target.value);
  }

  const _handleChangeObat = (e, val) => {
    setValObat(val)
    setShowQuantity(true);
  }

  const _handleCloseAddObat = () => {
    setAddObat(false)
    setShowQuantity(false)
  }

  const _handleChangeValObat = (e) => {
    setValObat(e.target.value)
  }

  const handleChangeQuantity = (e) => {
    setValObat({
      ...valObat,
      quantity: Number(e.target.value)
    })
  }

  const _handleSaveBill = () => {
    setConfirmSaveBill(true);
  }

  const _handleInsertObat = () => {
    let newVal = {
      ...valObat,
      total: valObat?.quantity * valObat?.charges
    }
    listObat.push(newVal);
    setAddObat(false);
  }

  const _handleGoToList = () => {
    window.location.reload();
  }

  const _handleSave = (e) => {
    e.preventDefault();
    let data = {
      ...dataBill, isBillFarmasi: true,
      billingFarmasi: {
        obatObatan: listObat,
        petugas: namaPetugas
      }
    }
    set(ref(db, 'pasien/' + data.queueNumber), { ...data });
    setModalSubmit(true);
    setLoading(true);
    setTimeout(() => { setLoading(false) }, 500);
  }

  const _renderModalSubmit = () => {
    return (
      <Modal open={modalSubmit}>
        <Box sx={styleModal}>
          {loading ?
            <Box sx={{ width: '100%' }}>
              <h3>Menyimpan Data...</h3>
              <LinearProgress />
            </Box> :
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <CheckCircle color='success' sx={{ fontSize: 150 }} />
              <h3>Penyimpanan data berhasil!</h3>
              <Button
                variant="contained"
                color="success"
                onClick={_handleGoToList}
              >
                Lihat List Pasien
              </Button>
            </div>
          }
        </Box>
      </Modal>
    );
  }

  const _renderAddObat = () => {
    let valListTindakan = dataObat.filter(md => 
      listObat.every(fd => fd.title !== md.title));
    return (
      <Modal open={addObat} onClose={_handleCloseAddObat}>
        <Box sx={styleAddObat}>
          <Autocomplete
            className='wrappField'
            disablePortal
            id="combo-box-demo"
            onChange={_handleChangeObat}
            options={valListTindakan}
            getOptionLabel={(option) => option && option.title}
            renderInput={(params) =>
              <TextField
                {...params}
                label="Jenis Obat"
                onChange={_handleChangeValObat}
              />
            }
          />
          {showQuantity &&
            <>
              <TextField
                disabled
                fullWidth
                className='wrappField'
                label="Harga Satuan (Rp.)"
                value={parser(valObat?.charges)}
              />
              <TextField
                fullWidth
                className='wrappField'
                label="Quantity"
                type="number"
                onChange={handleChangeQuantity}
              />
              <Button
                disabled={valObat?.quantity === '' || valObat?.quantity === undefined}
                variant="contained"
                sx={{ float: 'right', textTransform: 'capitalize' }}
                onClick={_handleInsertObat}
              >
                Simpan
              </Button>
            </>
          }
        </Box>
      </Modal>
    )
  }

  const _renderConfirmSaveBill = () => {
    return (
      <Modal open={confirmSaveBill}>
        <Box sx={styleConfirm}>
          <div style={{ alignItems: "center", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div><ErrorOutline style={{ fontSize: '80px', color: 'red' }} /></div>
            <div>Yakin akan menyimpan data?</div>
            <div>Pastikan data yang anda masukkan sudah sesuai!</div>
            <div style={{ marginTop: 10 }}>
              <Button
                variant="contained"
                onClick={_handleSave}
                style={{ textTransform: 'capitalize', marginRight: 5 }}
              >
                Simpan
              </Button>
              <Button
                variant="outlined"
                onClick={() => setConfirmSaveBill(false)}
                style={{ textTransform: 'capitalize', marginLeft: 5 }}
              >
                Periksa Kembali
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    )
  }

  return (
    <>
    {_renderAddObat()}
    {_renderConfirmSaveBill()}
    {_renderModalSubmit()}
    <Box sx={styleModalForm}>
      <IconButton
        onClick={_handleConfirmCloseObat}
        style={{ position: 'absolute', right: -15, top: -5, backgroundColor: '#dad9db' }}
      >
        <Close />
      </IconButton>
      <Grid container spacing={2} sx={{ borderBottom: '1px dashed grey', paddingBottom: 2 }}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div>No RM</div>
              <div>Nama Lengkap</div>
              <div>Alamat</div>
              <div>Jenis Kelamin</div>
            </Grid>
            <Grid item xs={1}>
              <div>:</div>
              <div>:</div>
              <div>:</div>
              <div>:</div>
            </Grid>
            <Grid item xs={8}>
              <div><b>{dataBill?.noRM}</b></div>
              <div><b>{dataBill?.namaLengkap}</b></div>
              <div><b>{dataBill?.alamat}</b></div>
              <div><b>{dataBill?.jenisKelamin}</b></div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div>Tgl Lahir</div>
              <div>Tgl Berkunjung</div>
              <div>Poliklinik</div>
              <div>DPJP</div>
            </Grid>
            <Grid item xs={1}>
              <div>:</div>
              <div>:</div>
              <div>:</div>
              <div>:</div>
            </Grid>
            <Grid item xs={8}>
              <div><b>{moment(dataBill?.tglLahir).format('DD-MM-YYYY')}</b></div>
              <div><b>{moment(dataBill?.tglBerkunjung).format('DD-MM-YYYY')}</b></div>
              <div><b>{dataBill?.poli}</b></div>
              <div><b>{dataBill?.dokterPoli}</b></div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ height: '90%', overflowY: 'auto' }}>
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            size="small"
            sx={{ marginTop: 2, marginBottom: 2, textTransform: 'capitalize' }}
            onClick={handleAddObat}
          >
            <Add /> Tambah Obat
          </Button>
          <FormControl size="small">
            <InputLabel id="demo-simple-select-label">Nama Petugas</InputLabel>
            <Select
              className='wrappFieldPetugas'
              error={namaPetugas === ''}
              id="outlined-select-currency"
              select
              label="Nama Petugas"
              name='pemeriksa'
              onChange={_handleChangePetugas}
              value={namaPetugas}
            >
              {['Upin, Amd.Farm', 'Ipin, Amd.Farm', 'Jarjit, Amd.Farm', 'Susanti, Amd.Farm', 'Mail, Amd.Farm'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {listObat?.length !== 0 &&
          <>
            <div style={{
              display: 'flex',
              backgroundColor: 'grey',
              padding: '15px',
              fontWeight: 'bold'
            }}>
              <div style={{ marginRight: '720px' }}>NAMA OBAT</div>
              <div style={{ marginRight: '60px' }}>HARGA SATUAN (Rp.)</div>
              <div>QUANTITY</div>
              {/* <div>TOTAL (Rp.)</div> */}
            </div>
            <TableContainer component={Paper} sx={{ overflowY: 'auto', height: '250px !important' }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableBody>
                  {listObat.map((val, idx) => {
                    return (
                      <StyledTableRow key={idx}>
                        <StyledTableCell>{val?.title}</StyledTableCell>
                        <StyledTableCell align="center">{parser(val?.charges)}</StyledTableCell>
                        <StyledTableCell align="center">{val?.quantity}</StyledTableCell>
                        {/* <StyledTableCell align="center">{parser(val?.charges * val?.quantity)}</StyledTableCell> */}
                      </StyledTableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
              <Button
                disabled={namaPetugas === ''}
                size="small"
                variant="contained"
                sx={{ float: 'right', marginTop: 2, textTransform: 'capitalize' }}
                onClick={_handleSaveBill}
              >
                Simpan Bill
              </Button>
          </>
        }
      </div>
    </Box>
    </>
  )
}