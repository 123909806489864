import styled from "@emotion/styled";
import { InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React, { Component } from "react";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#ebebeb',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

class FungsiHati extends Component {
  state = {
    liverFunction: null
  }

  _handleChange = (e) => {
    const { onValueLiver } = this.props;
    this.setState({
      liverFunction: {
        ...this.state.liverFunction,
        [e.target.name]: e.target.value
      }
    });
    setTimeout(() => { onValueLiver(this.state.liverFunction) }, 500);
  }

  render() {
    const { liverFunction } = this.state;
    return (
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell><b>PEMERIKSAAN</b></TableCell>
                <TableCell><b>HASIL</b></TableCell>
                <TableCell><b>NILAI RUJUKAN</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <TableCell component="th" scope="row">SGOT</TableCell>
                <TableCell>
                  <TextField
                    className="wrappFields"
                    name="sgot"
                    onChange={this._handleChange}
                    value={liverFunction?.sgot}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">/&#181;L</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell>5 - 40 /&#181;L</TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell component="th" scope="row">SGPT</TableCell>
                <TableCell>
                  <TextField
                    className="wrappFields"
                    name="sgpt"
                    onChange={this._handleChange}
                    value={liverFunction?.sgpt}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">/&#181;L</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell>7 - 56 /&#181;L</TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }
}

export default FungsiHati;