import React, { Component } from 'react';
import Login from './pages/Login';
import Resume from './pages/Resume';

class Home extends Component {
  state = {
    valueTab: 0,
    valLogin: localStorage.getItem('login'),
  }

  render() {
    const { valLogin } = this.state;
    if (valLogin) {
      return <Resume/>
    } else {
      return <Login/>
    }
  }
}

export default Home;