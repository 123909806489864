export const dataObat = [
    { title: 'Asam Mefenamat 500 mg tablet', charges: 300 },
    { title: 'Lansoprazole 30 mg capsule', charges: 560 },
    { title: 'Sucralfate 500 mg', charges: 13174 },
    { title: 'Aspilet chewable 80 mg tablet', charges: 583 },
    { title: 'Calk (Clacuym Lactat) 500 mg tablet', charges: 114 },
    { title: 'Dopamet 250 mg tablet', charges: 2345 },
    { title: 'Tambah Darah tablet', charges: 639 },
    { title: 'Amlodipine 5 mg tablet', charges: 300 },
    { title: 'Paracetamol 500 mg tablet', charges: 250 },
];

export const hargaPemeriksaan = [
    { title: 'Jasa Sarana Klinis', charges: 69000 },
    { title: 'Konsultasi Dokter', charges:  169000},
    { title: 'USG Inguinal', charges: 875000 },
    { title: 'Pemeriksaan USG Obstetri Abdominal 2D', charges: 378000 }
];

export const parser = (nom) =>{
    if(nom){
      nom = parseInt(nom.toString().replace(/[^0-9]/g, ''));
      return nom.toString().replace('.','').split('').reverse().join('').match(/\d{1,3}/g).join('.').split('').reverse().join('');
    }
    else
      return null;
  };
  
  export const revParse = (nom)=>{
    if(nom){
      return parseInt(nom.toString().split('.').join(''));
    }else return null;
  };