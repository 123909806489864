import styled from "@emotion/styled";
import { InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React, { Component } from "react";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#ebebeb',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

class FungsiGinjal extends Component {
  state = {
    kidneyFunction: null
  }

  _handleChange = (e) => {
    const { onValueKidney } = this.props;
    this.setState({
      kidneyFunction: {
        ...this.state.kidneyFunction,
        [e.target.name]: e.target.value
      }
    });
    setTimeout(() => { onValueKidney(this.state.kidneyFunction) }, 500);
  }

  render() {
    const { kelamin } = this.props;
    const { kidneyFunction } = this.state;
    return (
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell><b>PEMERIKSAAN</b></TableCell>
                <TableCell><b>HASIL</b></TableCell>
                <TableCell><b>NILAI RUJUKAN</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <TableCell component="th" scope="row">Ureum</TableCell>
                <TableCell>
                  <TextField
                    className="wrappFields"
                    name="ureum"
                    onChange={this._handleChange}
                    value={kidneyFunction?.ureum}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">/&#181;L</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell>{kelamin === 'Laki-Laki' ? '8 - 24' : '6 - 21'} /&#181;L</TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell component="th" scope="row">Kreatinin</TableCell>
                <TableCell>
                  <TextField
                    className="wrappFields"
                    name="kreatinin"
                    onChange={this._handleChange}
                    value={kidneyFunction?.kreatinin}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">/&#181;L</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell>{kelamin === 'Laki-Laki' ? '0,6 - 1,2' : '0,5 - 1,1'} /&#181;L</TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell component="th" scope="row">Asam Urat</TableCell>
                <TableCell>
                  <TextField
                    className="wrappFields"
                    name="asamUrat"
                    onChange={this._handleChange}
                    value={kidneyFunction?.asamUrat}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">/&#181;L</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell>{kelamin === 'Laki-Laki' ? '3,4 - 7,0' : '2,4 - 6,0'} /&#181;L</TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }
}

export default FungsiGinjal;