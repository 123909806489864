/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import './styles.css';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Autocomplete, Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, Modal, OutlinedInput, Pagination, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import { Add, CheckCircle, Close, ControlPoint, ErrorOutline, HelpOutline, Print, Search } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import moment from "moment";
import { Document, PDFViewer, Page, View } from "@react-pdf/renderer";
import PrintForm from "./printForm";
import { onValue, ref, set } from "firebase/database";
import StartFirebase from "../../configFirebase";
import { dataObat, hargaPemeriksaan, parser } from "./dataMaster";
import RenderNewBillFarmasi from './newBillFarmasi';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const styleModalForm = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  height: '90%',
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const styleAddObat = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: '35%',
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

const styleAddTindakan = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: '25%',
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

const styleConfirm = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: '25%',
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

const viewer = {
  width: '100%',
  height: '600px',
};

const page = {
  backgroundColor: "white",
  color: "black",
  fontSize: 12
};

const section = {
  margin: 10,
  padding: 10,
};

const pageSize = 5;

const db = StartFirebase();

export default function ListPasien(props) {
  const [state, setState] = useState({
    search: '',
    pagination: { count: 0, from: 0, to: pageSize },
  });
  const [dataTable, setDataTable] = useState([]);
  const [dataPasiens, setDataPasiens] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newBill, setNewBill] = useState(false);
  const [dataBill, setDataBill] = useState(null);
  const [addObat, setAddObat] = useState(false);
  const [addTindakan, setAddTindakan] = useState(false);
  const [valObat, setValObat] = useState(null);
  const [showQuantity, setShowQuantity] = useState(false);
  const [listObat, setListObat] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [confirmSaveBill, setConfirmSaveBill] = useState(false);
  const [valTindakan, setValTindakan] = useState(null);
  const [listTindakan, setListTindakan] = useState([]);
  const [namaPetugas, setNamaPetugas] = useState('');
  const [modalSubmit, setModalSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  // Farmasi
  const [newBillFarmasi, setNewBillFarmasi] = useState(false);
  const [confirmFarmasi, setConfirmFarmasi] = useState(false);


  useEffect(() => {
    const dbRef = ref(db, 'pasien');
    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach(childSnapshot => {
        let data = childSnapshot.val();
        records.push(data);
      });
      setState({
        ...state,
        pagination: {
          ...state.pagination,
          count: records.length
        }
      });
      setDataTable(records);
    });
  }, []);

  useEffect(() => {
    const results = props?.dataPasien?.filter((val) => {
      return val?.namaLengkap?.toLowerCase()?.includes(state?.search?.toLowerCase())
    }) ?? [];
    setDataTable(results);
  }, [state?.search]);

  const loginAs = localStorage.getItem('billing');

  const _handleChangeSearch = (e) => {
    setState({ ...state, search: e.target.value });
  }

  const _handleSave = (e) => {
    e.preventDefault();
    let data = {
      ...dataBill, isBillKassa: true,
      billingKassa: {
        obatObatan: dataBill?.billingFarmasi?.obatObatan || [],
        jenisTinakan: listTindakan,
        petugas: namaPetugas
      }
    }
    set(ref(db, 'pasien/' + data.queueNumber), { ...data });
    setModalSubmit(true);
    setLoading(true);
    setTimeout(() => { setLoading(false) }, 500);
  }

  const _handleSaveBill = () => {
    setConfirmSaveBill(true);
  }

  const _handleGoToList = () => {
    window.location.reload();
  }

  const _handleInsertObat = () => {
    let newVal = {
      ...valObat,
      total: valObat?.quantity * valObat?.charges
    }
    listObat.push(newVal);
    setAddObat(false);
  }

  const _handleChangePetugas = (e) => {
    setNamaPetugas(e.target.value);
  }

  const _handleInsertTindakan = () => {
    listTindakan.push(valTindakan);
    setAddTindakan(false);
  }

  const _handleConfirmCloseObat = () => {
    setConfirm(true);
  }

  const _handleChangeObat = (e, val) => {
    setValObat(val)
    setShowQuantity(true);
  }

  const _handleChangeTindakan = (e, val) => {
    setValTindakan(val)
  }

  const _handleChangeValObat = (e) => {
    setValObat(e.target.value)
  }

  const _handlePrint = (noReg) => () => {
    let fillData = (dataTable || []).filter(val => val?.noRegistrasi === noReg);
    setDataPasiens(fillData);
    setShowModal(true);
  }

  const _handleCloseModal = () => {
    setShowModal(false);
  }

  // const handleAddObat = () => {
  //   setAddObat(true);
  //   setShowQuantity(false)
  // }

  const handleAddTindakan = () => {
    setAddTindakan(true)
  }

  const _handleCloseAddObat = () => {
    setAddObat(false)
    setShowQuantity(false)
    setNewBill(true)
  }

  const _handleCloseAddTindakan = () => {
    setAddTindakan(false)
  }

  const _handleChangePagination = (e, page) => {
    const from = (page - 1) * pageSize;
    const to = (page - 1) * pageSize + pageSize;
    setState({
      ...state,
      pagination: {
        ...state.pagination,
        from, to
      }
    });
  }

  const handleCreateBill = (value) => () => {
    if (loginAs === 'Kassa') {
      setNewBill(true);
      setDataBill(value);
    } else {
      setNewBillFarmasi(true);
      setDataBill(value);
    }
  }

  const handleCloseCreateBill = () => {
    setNewBill(false);
    setDataBill(null);
    setListObat([]);
    setListTindakan([]);
    setConfirm(false);

    // Farmasi
    setNewBillFarmasi(false);
    setConfirmFarmasi(false);
  }

  const handleChangeQuantity = (e) => {
    setValObat({
      ...valObat,
      quantity: Number(e.target.value)
    })
  }

  const _handleCloseBillFarmasi = (val) => {
    setConfirmFarmasi(val);
  }

  const _handleCloseConfirm = () => {
    setConfirm(false);
    setConfirmFarmasi(false);
  }

  const _renderConfirm = () => {
    return (
      <Modal open={confirm || confirmFarmasi}>
        <Box sx={styleConfirm}>
          <div style={{ alignItems: "center", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div><HelpOutline style={{ fontSize: '80px', color: 'red' }} /></div>
            <div>Yakin akan kembali?</div>
            <div>Data yang anda masukkan tidak akan tersimpan!</div>
            <div style={{ marginTop: 10 }}>
              <Button
                variant="contained"
                onClick={handleCloseCreateBill}
                style={{ textTransform: 'capitalize', marginRight: 5 }}
              >
                Ya
              </Button>
              <Button
                variant="outlined"
                onClick={_handleCloseConfirm}
                style={{ textTransform: 'capitalize', marginLeft: 5 }}
              >
                Tidak
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    )
  }

  const _renderConfirmSaveBill = () => {
    return (
      <Modal open={confirmSaveBill}>
        <Box sx={styleConfirm}>
          <div style={{ alignItems: "center", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div><ErrorOutline style={{ fontSize: '80px', color: 'red' }} /></div>
            <div>Yakin akan menyimpan data?</div>
            <div>Pastikan data yang anda masukkan sudah sesuai!</div>
            <div style={{ marginTop: 10 }}>
              <Button
                variant="contained"
                onClick={_handleSave}
                style={{ textTransform: 'capitalize', marginRight: 5 }}
              >
                Simpan
              </Button>
              <Button
                variant="outlined"
                onClick={() => setConfirmSaveBill(false)}
                style={{ textTransform: 'capitalize', marginLeft: 5 }}
              >
                Periksa Kembali
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    )
  }

  const _renderAddObat = () => {
    return (
      <Modal open={addObat} onClose={_handleCloseAddObat}>
        <Box sx={styleAddObat}>
          <Autocomplete
            className='wrappField'
            disablePortal
            id="combo-box-demo"
            onChange={_handleChangeObat}
            options={dataObat}
            getOptionLabel={(option) => option && option.title}
            renderInput={(params) =>
              <TextField
                {...params}
                label="Jenis Obat"
                onChange={_handleChangeValObat}
              />
            }
          />
          {showQuantity &&
            <>
              <TextField
                disabled
                fullWidth
                className='wrappField'
                label="Harga Satuan (Rp.)"
                value={parser(valObat?.charges)}
              />
              <TextField
                fullWidth
                className='wrappField'
                label="Quantity"
                type="number"
                onChange={handleChangeQuantity}
              />
              <Button
                disabled={valObat?.quantity === '' || valObat?.quantity === undefined}
                variant="contained"
                sx={{ float: 'right', textTransform: 'capitalize' }}
                onClick={_handleInsertObat}
              >
                Simpan
              </Button>
            </>
          }
        </Box>
      </Modal>
    )
  }

  const _renderAddTindakan = () => {
    let valListTindakan = hargaPemeriksaan.filter(md => 
      listTindakan.every(fd => fd.title !== md.title));
    return (
      <Modal open={addTindakan} onClose={_handleCloseAddTindakan}>
        <Box sx={styleAddTindakan}>
          <Autocomplete
            className='wrappField'
            disablePortal
            id="combo-box-demo"
            onChange={_handleChangeTindakan}
            options={valListTindakan}
            getOptionLabel={(option) => option && option.title}
            renderInput={(params) =>
              <TextField
                {...params}
                label="Jenis Tindakan"
              />
            }
          />
          {valTindakan !== null &&
            <>
              <TextField
                disabled
                fullWidth
                className='wrappField'
                label="Harga (Rp.)"
                value={parser(valTindakan?.charges)}
              />
              <Button
                variant="contained"
                sx={{ float: 'right', textTransform: 'capitalize' }}
                onClick={_handleInsertTindakan}
              >
                Simpan
              </Button>
            </>
          }
        </Box>
      </Modal>
    )
  }

  const _renderModal = () => {
    return (
      <Modal open={showModal} onClose={_handleCloseModal}>
        <Box sx={styleModalForm}>
          <PDFViewer style={viewer}>
            <Document>
              <Page size="A4" style={page} orientation="portrait">
                <View style={section}>
                  <PrintForm data={dataPasiens} />
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </Box>
      </Modal>
    )
  }

  const _renderModalNewBillFarmasi = () => {
    return (
      <Modal open={newBillFarmasi}>
        <RenderNewBillFarmasi
          onConfirm={_handleCloseBillFarmasi}
          dataBill={dataBill}
        />
      </Modal>
    )
  }

  const _renderModalNewBill = () => {
    const listDrugs = dataBill?.billingFarmasi?.obatObatan || [];
    return (
      <Modal open={newBill}>
        <Box sx={styleModalForm}>
          <IconButton
            onClick={_handleConfirmCloseObat}
            style={{ position: 'absolute', right: -15, top: -5, backgroundColor: '#dad9db' }}
          >
            <Close />
          </IconButton>
          <Grid container spacing={2} sx={{ borderBottom: '1px dashed grey', paddingBottom: 2 }}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <div>No RM</div>
                  <div>Nama Lengkap</div>
                  <div>Alamat</div>
                  <div>Jenis Kelamin</div>
                </Grid>
                <Grid item xs={1}>
                  <div>:</div>
                  <div>:</div>
                  <div>:</div>
                  <div>:</div>
                </Grid>
                <Grid item xs={8}>
                  <div><b>{dataBill?.noRM}</b></div>
                  <div><b>{dataBill?.namaLengkap}</b></div>
                  <div><b>{dataBill?.alamat}</b></div>
                  <div><b>{dataBill?.jenisKelamin}</b></div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <div>Tgl Lahir</div>
                  <div>Tgl Berkunjung</div>
                  <div>Poliklinik</div>
                  <div>DPJP</div>
                </Grid>
                <Grid item xs={1}>
                  <div>:</div>
                  <div>:</div>
                  <div>:</div>
                  <div>:</div>
                </Grid>
                <Grid item xs={8}>
                  <div><b>{moment(dataBill?.tglLahir).format('DD-MM-YYYY')}</b></div>
                  <div><b>{moment(dataBill?.tglBerkunjung).format('DD-MM-YYYY')}</b></div>
                  <div><b>{dataBill?.poli}</b></div>
                  <div><b>{dataBill?.dokterPoli}</b></div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div style={{ height: '90%', overflowY: 'auto' }}>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: 10 }}>
              {/* <Button
              variant="contained"
              size="small"
              sx={{ marginTop: 2, marginBottom: 2, textTransform: 'capitalize' }}
              onClick={handleAddObat}
            >
              <Add /> Tambah Obat
            </Button> */}
              <FormControl size="small">
                <InputLabel id="demo-simple-select-label">Nama Petugas</InputLabel>
                <Select
                  className='wrappFieldPetugas'
                  error={namaPetugas === ''}
                  id="outlined-select-currency"
                  select
                  label="Nama Petugas"
                  name='pemeriksa'
                  onChange={_handleChangePetugas}
                  value={namaPetugas}
                >
                  {['Nadia', 'Fithri', 'Cindy', 'Putri', 'Asmara'].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {listDrugs?.length !== 0 &&
              <>
                <div style={{
                  display: 'flex',
                  backgroundColor: 'grey',
                  padding: '15px',
                  fontWeight: 'bold',
                  marginTop: 10
                }}>
                  <div style={{ marginRight: '580px' }}>NAMA OBAT</div>
                  <div style={{ marginRight: '40px' }}>HARGA SATUAN (Rp.)</div>
                  <div style={{ marginRight: '80px' }}>QUANTITY</div>
                  <div>TOTAL (Rp.)</div>
                </div>
                <TableContainer component={Paper} sx={{ overflowY: 'auto', height: '150px !important' }}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableBody>
                      {listDrugs.map((val, idx) => {
                        return (
                          <StyledTableRow key={idx}>
                            <StyledTableCell>{val?.title}</StyledTableCell>
                            <StyledTableCell align="center">{parser(val?.charges)}</StyledTableCell>
                            <StyledTableCell align="center">{val?.quantity}</StyledTableCell>
                            <StyledTableCell align="center">{parser(val?.charges * val?.quantity)}</StyledTableCell>
                          </StyledTableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            }
            <div>
              <Button
                variant="contained"
                size="small"
                sx={{ marginTop: 2, marginBottom: 2, textTransform: 'capitalize' }}
                onClick={handleAddTindakan}
              >
                <Add /> Tambah Tindakan
              </Button>
            </div>
            {listTindakan?.length !== 0 &&
              <>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: 'grey',
                  padding: '15px',
                  fontWeight: 'bold'
                }}>
                  <div>JENIS TINDAKAN</div>
                  <div style={{ marginRight: '350px' }}>HARGA (Rp.)</div>
                </div>
                <TableContainer component={Paper} sx={{ overflowY: 'auto', height: '150px !important' }}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableBody>
                      {listTindakan.map((val, idx) => {
                        return (
                          <StyledTableRow key={idx}>
                            <StyledTableCell>{val?.title}</StyledTableCell>
                            <StyledTableCell>{parser(val?.charges)}</StyledTableCell>
                          </StyledTableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            }
            {
              (listObat?.length !== 0 || listTindakan?.length !== 0) &&
              <Button
                disabled={namaPetugas === ''}
                size="small"
                variant="contained"
                sx={{ float: 'right', marginTop: 2, textTransform: 'capitalize' }}
                onClick={_handleSaveBill}
              >
                Simpan Bill
              </Button>
            }
          </div>
        </Box>
      </Modal>
    )
  }

  const _renderModalSubmit = () => {
    return (
      <Modal open={modalSubmit}>
        <Box sx={styleModal}>
          {loading ?
            <Box sx={{ width: '100%' }}>
              <h3>Menyimpan Data...</h3>
              <LinearProgress />
            </Box> :
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <CheckCircle color='success' sx={{ fontSize: 150 }} />
              <h3>Penyimpanan data berhasil!</h3>
              <Button
                variant="contained"
                color="success"
                onClick={_handleGoToList}
              >
                Lihat List Pasien
              </Button>
            </div>
          }
        </Box>
      </Modal>
    );
  }

  const _renderIcon = (val) => {
    if (loginAs === 'Farmasi') {
      if (val?.isBillFarmasi) {
        return <IconButton onClick={_handlePrint(val?.noRegistrasi)}><Print style={{ color: '#00b86e' }} /></IconButton>
      } else {
        if (val?.isBillKassa) {
          return;
        } else {
          return (
            <Tooltip title="Buat Bill" placement="top">
              <IconButton onClick={handleCreateBill(val)}>
                <ControlPoint />
              </IconButton>
            </Tooltip>
          )
        }
      }
    } else {
      if (val?.isBillKassa) {
        return <IconButton onClick={_handlePrint(val?.noRegistrasi)}><Print style={{ color: '#00b86e' }} /></IconButton>
      } else {
        return (
          <Tooltip title="Buat Bill" placement="top">
            <IconButton onClick={handleCreateBill(val)}>
              <ControlPoint />
            </IconButton>
          </Tooltip>
        )
      }
    }
  }

  const { search, pagination } = state;
  const newDataTable = dataTable?.filter(val => val?.isInspection);
  const newData = newDataTable?.slice(pagination?.from, pagination?.to);
  return (
    <div className="containerContent">
      {_renderModal()}
      {_renderModalNewBill()}
      {_renderModalNewBillFarmasi()}
      {_renderAddObat()}
      {_renderAddTindakan()}
      {_renderConfirm()}
      {_renderConfirmSaveBill()}
      {_renderModalSubmit()}
      <FormControl sx={{ marginBottom: 1, marginTop: -1, float: 'right' }}>
        <OutlinedInput
          endAdornment={
            <InputAdornment>
              <Search />
            </InputAdornment>
          }
          onChange={_handleChangeSearch}
          placeholder="Cari pasien..."
          type="text"
          value={search}
          variant="standart"
          size='small'
          color="primary"
        />
      </FormControl>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>NO</StyledTableCell>
              <StyledTableCell>NAMA PASIEN</StyledTableCell>
              <StyledTableCell>NO RM</StyledTableCell>
              <StyledTableCell>ASAL POLIKLINIK</StyledTableCell>
              <StyledTableCell>TANGGAL BERKUNJUNG</StyledTableCell>
              <StyledTableCell>JAM BERKUNJUNG</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataTable?.length === 0 ?
              <StyledTableRow>
                <StyledTableCell colSpan={8}>Tidak Ada Pasien</StyledTableCell>
              </StyledTableRow> :
              (newData || []).map((val, idx) => {
                return (
                  <StyledTableRow key={idx}>
                    <StyledTableCell component="th" scope="row">{idx + 1}</StyledTableCell>
                    <StyledTableCell>{val?.namaLengkap}</StyledTableCell>
                    <StyledTableCell>{val?.noRM}</StyledTableCell>
                    <StyledTableCell>{val?.poli}</StyledTableCell>
                    <StyledTableCell>{moment(val?.tglBerkunjung).format('DD/MM/YYYY')}</StyledTableCell>
                    <StyledTableCell>{val?.jamBerkunjung} WIB</StyledTableCell>
                    <StyledTableCell sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      {_renderIcon(val)}
                      {val?.isBillKassa &&
                        <div style={{
                          backgroundColor: '#039623',
                          padding: 4,
                          borderRadius: '8px',
                          width: 'fit-content',
                          height: 'fit-content',
                          color: '#FFF'
                        }}>Selesai</div>
                      }
                      {/* {(loginAs === 'Farmasi' ? val?.isBillFarmasi : val?.isBillKassa) ?
                        <IconButton onClick={_handlePrint(val?.noRegistrasi)}><Print style={{ color: '#00b86e' }} /></IconButton> :
                        <Tooltip title="Buat Bill" placement="top">
                          <IconButton onClick={handleCreateBill(val)}>
                            <ControlPoint />
                          </IconButton>
                        </Tooltip>
                      } */}
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        justifyContent={"flex-end"}
        alignItems={"center"}
        display={"flex"}
        sx={{
          position: 'fixed',
          bottom: '20px',
          right: '10px',
        }}
      >
        <Pagination
          color='primary'
          count={Math.ceil(pagination?.count / pageSize)}
          onChange={_handleChangePagination}
        />
      </Box>
    </div>
  )
}
